import API, { API_URL } from "../index";
export const getCompanyById = async (id, body = {}) => {
  return await API.user.post(API_URL.GET_COMPANY_BY_ID(id), body);
};

export const getCompanyByIdToEdit = async (id, body = {}) => {
  return await API.withHeaders.post(
    API_URL.GET_COMPANY_BY_ID_TO_EDIT(id),
    body
  );
};

export const changeFavoriteCompany = async (body = {}) => {
  return await API.withHeaders.post(API_URL.CHANGE_FAVORITE_COMPANY, body);
};

export const getAllCompanies = async (args) => {
  return await API.user.post(API_URL.GET_ALL_COMPANIES, args);
};

export const searchCompanies = async (args) => {
  return await API.user.post(API_URL.SEARCH_COMPANIES, args);
};

export const searchSuggestions = async (args) => {
  return await API.user.post(API_URL.SEARCH_SUGGESTIONS, args);
};

export const createCompany = async (data) => {
  return await API.withHeaders.post(API_URL.CREATE_COMPANY, data);
};

export const createJob = async (data) => {
  return await API.withHeaders.post(API_URL.CREATE_JOB, data);
};

export const updateJob = async (id, data) => {
  return await API.withHeaders.put(API_URL.UPDATE_JOB(id), data);
};

export const deleteJob = async (id) => {
  return await API.withHeaders.delete(API_URL.DELETE_JOB(id));
};

export const getAllJobs = async (data) => {
  return await API.withHeaders.post(API_URL.GET_ALL_JOBS, data);
};

export const getAllSubmission = async () => {
  return await API.withHeaders.get(API_URL.GET_ALL_BOOKING);
};
export const getAllBadges = async () => {
  return await API.withHeaders.get(API_URL.GET_ALL_BADGES);
};
export const searchJobs = async (data) => {
  return await API.user.post(API_URL.SEARCH_JOBS, data);
};

export const getJobById = async (id) => {
  return await API.withHeaders.get(API_URL.GET_JOB_BY_ID(id));
};

export const createJobApplication = async (data) => {
  return await API.withHeaders.post(API_URL.CREATE_JOB_APPLICATION, data);
};

export const updateCompany = async (id, data) => {
  return await API.withHeaders.put(API_URL.UPDATE_COMPANY(id), data);
};

export const createSocials = async (data) => {
  return await API.withHeaders.post(API_URL.CREATE_SOCIALS, data);
};

export const updateSocials = async (id, data) => {
  return await API.withHeaders.put(API_URL.UPDATE_SOCIALS(id), data);
};

export const createOfficeHours = async (data) => {
  return await API.withHeaders.post(API_URL.CREATE_OFFICE_HOURS, data);
};

export const updateOfficeHours = async (id, data) => {
  return await API.withHeaders.put(API_URL.UPDATE_OFFICE_HOURS(id), data);
};

export const createVideos = async (data) => {
  return await API.withHeaders.post(API_URL.CREATE_VIDEOS, data);
};

export const deleteVideo = async (id) => {
  return await API.withHeaders.delete(API_URL.DELETE_VIDEO(id));
};
export const deleteContactById = async (id) => {
  return await API.withHeaders.delete(API_URL.DELETE_CONTACT(id));
};

export const viewVideos = async (id) => {
  return await API.user.post(API_URL.VIEW_VIDEO(id));
};
export const updateVideo = async (id, data) => {
  return await API.withHeaders.put(API_URL.UPDATE_VIDEO(id), data);
};

export const viewCompany = async (id) => {
  return await API.user.post(API_URL.VIEW_COMPANY(id));
};

export const viewEvent = async (id) => {
  return await API.user.post(API_URL.VIEW_EVENT(id));
};

export const uploadVideos = async (companyId, data) => {
  return await API.withHeaders.post(API_URL.UPLOAD_VIDEO(companyId), data);
};

export const updateVideoIndexes = async (companyId, data) => {
  return await API.withHeaders.post(
    API_URL.UPDATE_VIDEO_INDEXES(companyId),
    data
  );
};
export const getAllVideos = async (data) => {
  return await API.user.post(API_URL.GET_ALL_VIDEOS, data);
};

export const getAllRecentlyVideos = async (id) => {
  return await API.withHeaders.post(API_URL.RECENTLY_ADDED_VIDEO(id));
};

export const getMostlyViewedVideo = async (id) => {
  return await API.withHeaders.get(API_URL.MOSTLY_VIEWED_VIDEO(id));
};
export const getAllNewsLetter = async () => {
  return API.withHeaders.get(API_URL.GET_ALL_NEWSLETTER);
};
export const createPrice = async (data) => {
  return await API.withHeaders.post(API_URL.CREATE_PRICE, data);
};
export const createBadge = async (data) => {
  return await API.withHeaders.post(API_URL.CREATE_BADGE, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const createSubscription = async (data) => {
  return await API.withHeaders.post(API_URL.CREATE_SUBSCRIPTION, data);
};

export const getAllUsers = async () => {
  return await API.withHeaders.get(API_URL.GET_ALL_USERS);
};
export const getAllSubscriptions = async () => {
  return await API.withHeaders.get(API_URL.GET_ALL_SUBSCRIPTIONS);
};
export const deletSubscription = async (id) => {
  return await API.withHeaders.delete(API_URL.DELETE_SUBSCRIPTION(id));
};
export const updateSubscription = async (id, data) => {
  return await API.withHeaders.put(API_URL.UPDATE_SUBSCRIPTON(id), data);
};
export const getAllEvents = async (data) => {
  return await API.withHeaders.post(API_URL.GET_ALL_EVENTS, data);
};

export const CreateEvent = async (data) => {
  return await API.withHeaders.post(API_URL.CREATE_EVENT, data);
};

export const updateEvent = async (id, data) => {
  return await API.withHeaders.put(API_URL.UPDATE_EVENT(id), data);
};

export const getEventById = async (id, data) => {
  return await API.withHeaders.get(API_URL.GET_EVENT_BY_ID(id), data);
};

export const getBookingById = async (id) => {
  return await API.withHeaders.post(API_URL.GET_BOOKING_BY_ID(id));
};
export const deleteEvent = async (id) => {
  return await API.withHeaders.delete(API_URL.DELETE_EVENT(id));
};

export const deleteBooking = async (id) => {
  return await API.withHeaders.delete(API_URL.DELETE_BOOKING(id));
};
export const deleteBadge = async (id) => {
  return await API.withHeaders.delete(API_URL.DELETE_BADGE(id));
};
export const updateBadge = async (id, data) => {
  return await API.withHeaders.put(API_URL.UPDATE_BADGE(id), data);
};

export const updatePrice = async (id, data) => {
  return await API.withHeaders.put(API_URL.UPDATE_PRICE(id), data);
};

export const createPartnerRequest = async (data) => {
  return await API.withHeaders.post(API_URL.CREATE_PARTNER_REQUEST, data);
};

export const updatePartnerRequest = async (id, data) => {
  return await API.withHeaders.put(API_URL.UPDATE_PARTNER_REQUEST(id), data);
};

export const getAllNotifications = async (data) => {
  return await API.withHeaders.post(API_URL.GET_ALL_NOTIFICATIONS, data);
};

export const seenAllNotifications = async (data) => {
  return await API.withHeaders.post(API_URL.SEEN_ALL_NOTIFICATIONS, data);
};

export const createAd = async (data) => {
  return await API.withHeaders.post(API_URL.CREATE_AD, data);
};

export const updateAd = async (id, data) => {
  return await API.withHeaders.put(API_URL.UPDATE_AD(id), data);
};

export const getAllAds = async (data) => {
  return await API.user.post(API_URL.GET_ALL_ADS, data);
};

export const companyDataFilter = async (data) => {
  return await API.withHeaders.post(API_URL.COMPANY_FILTER, data);
};
export const getRandomAd = async () => {
  return await API.user.post(API_URL.GET_RANDOM_ADS);
};

export const getRandomAdVertical = async () => {
  return await API.user.post(API_URL.GET_RANDOM_ADS_VERTICAL);
};

export const getAllPermissions = async () => {
  return await API.withHeaders.get(API_URL.GET_ALL_PERMISSIONS);
};

export const deletePermission = async (id) => {
  return await API.withHeaders.delete(API_URL.DELETE_PERMISSION(id));
};

export const updatePermission = async (id, data) => {
  return await API.withHeaders.put(API_URL.UPDATE_PERMISSION(id), data);
};

// Role Apis
export const createRole = async (data) => {
  return await API.withHeaders.post(API_URL.CREATE_ROLE, data);
};

export const getAllRoles = async () => {
  return await API.withHeaders.get(API_URL.GET_ALL_ROLES);
};

export const deleteRole = async (id) => {
  return await API.withHeaders.delete(API_URL.DELETE_ROLE(id));
};

export const updateRole = async (id, data) => {
  return await API.withHeaders.put(API_URL.UPDATE_ROLE(id), data);
};
export const getRoleById = async (id) => {
  return await API.withHeaders.get(API_URL.GET_ROLE_BY_ID(id));
};

// Create Reels

export const createReel = async (data) => {
  return await API.withHeaders.post(API_URL.CREATE_REEL, data);
};

export const getAllReels = async () => {
  return await API.withHeaders.get(API_URL.GE_ALL_REELS);
}
export const getInvoice = async (data) => {
  return API.withHeaders.post(API_URL.GET_INVOICE, data);
};
 

export const viewReel = async (slug) => {
  return await API.withHeaders.get(API_URL.VIEW_REEL(slug));
};
