import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";

import PublicLayout from "components/Layout/Layout";
import { Loadable } from "components/PageLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "components/Loader";

const routesWithoutlocalStorage = [
  "/login",
  "/registration",
  "/neuespasswort",
  "/passwortzuruecksetzen/:id",
  "/suchen",
  "/profile/:id",
  "/jobs/:id",
  "/impressum",
  "/datenschutzerklaerung",
  "/agb",
  "/ueber-uns",
  "/kontakt",
  "/pro-mitgliedschaft",
  "/Tipps",
  "/reels",
  "/allejobs",
  "/",
];

const Home = Loadable(lazy(() => import("./pages/Home")));
const Register = Loadable(lazy(() => import("./pages/BasicRegisteration")));
const Login = Loadable(lazy(() => import("./pages/Login")));
const Forgot = Loadable(lazy(() => import("./pages/Forgot")));
const ResetPassword = Loadable(lazy(() => import("./pages/ResetPassword")));
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const AccessDenied = Loadable(lazy(() => import("./pages/AccessDenied")));
const Impressum = Loadable(lazy(() => import("./pages/Impressum")));
const Datenschutz = Loadable(lazy(() => import("./pages/Datenschutz")));
const AGB = Loadable(lazy(() => import("./pages/AGB")));
const Tipps = Loadable(lazy(() => import("./pages/Tipps")));
const ReelPage = Loadable(lazy(() => import("./pages/ReelPage")));
const AllJobs = Loadable(lazy(() => import("./pages/AllJobs")));
const Widerrufsbelehrung = Loadable(
  lazy(() => import("./pages/Widerrufsbelehrung"))
);
const ZufriedeneKunden = Loadable(
  lazy(() => import("./pages/ZufriedeneKunden"))
);
const NewsLetterPage = Loadable(lazy(() => import("./pages/NewsLetterPage")));
const UeberUns = Loadable(lazy(() => import("./pages/UeberUns")));
const Kontakt = Loadable(lazy(() => import("./pages/Kontakt")));
const JobPage = Loadable(lazy(() => import("./pages/Job")));
const AdPage = Loadable(lazy(() => import("./pages/Ad")));
const UserDashboard = Loadable(lazy(() => import("./pages/UserDashboard")));
const CompanyRegistration = Loadable(
  lazy(() => import("./pages/CompanyRegistration"))
);
const CompanySettings = Loadable(
  lazy(() => import("./pages/UserDashboard/Settings"))
);
const socialSettings = Loadable(
  lazy(() => import("./pages/UserDashboard/Social"))
);
const CompanyContact = Loadable(
  lazy(() => import("./pages/UserDashboard/ContactInformation"))
);
const HoursSettings = Loadable(
  lazy(() => import("./pages/UserDashboard/OpeningHours"))
);
const VideosSettings = Loadable(
  lazy(() => import("./pages/UserDashboard/Videos"))
);
const ManageEvents = Loadable(
  lazy(() => import("./pages/UserDashboard/Events"))
);
const ManageJobs = Loadable(lazy(() => import("./pages/UserDashboard/Jobs")));
const ManageServices = Loadable(
  lazy(() => import("./pages/UserDashboard/Services"))
);
const ManageAbout = Loadable(lazy(() => import("./pages/UserDashboard/About")));
const MangePriceList = Loadable(
  lazy(() => import("./pages/UserDashboard/PriceList"))
);
const ManageMenuList = Loadable(
  lazy(() => import("./pages/UserDashboard/MenuList"))
);
const ManagePartners = Loadable(
  lazy(() => import("./pages/UserDashboard/Partners"))
);
const ManageFavourite = Loadable(
  lazy(() => import("./pages/UserDashboard/Favourite"))
);
const MangeFiles = Loadable(
  lazy(() => import("./pages/UserDashboard/MyFiles"))
);
const ad = Loadable(lazy(() => import("./pages/UserDashboard/Ad")));

// Admin Dashboard
const ManageCompanies = Loadable(
  lazy(() => import("./pages/AdminDashboard/ManageCompanies"))
);
const BadgesPage = Loadable(
  lazy(() => import("./pages/AdminDashboard/Badges"))
);
const Industries = Loadable(
  lazy(() => import("./pages/AdminDashboard/Industries"))
);
const Recommendations = Loadable(
  lazy(() => import("./pages/AdminDashboard/Recommendations"))
);
const Reels = Loadable(
  lazy(() => import("./pages/AdminDashboard/Reels"))
);
const Monitoring = Loadable(
  lazy(() => import("./pages/AdminDashboard/Monitoring"))
);
const CompanyAds = Loadable(
  lazy(() => import("./pages/AdminDashboard/CompanyAds"))
);
const Subscription = Loadable(
  lazy(() => import("./pages/AdminDashboard/Subscriptions"))
);
const userSubmissions = Loadable(
  lazy(() => import("./pages/AdminDashboard/UserSubmissions"))
);
const userRoles = Loadable(
  lazy(() => import("./pages/AdminDashboard/UserRoles"))
);
const userContactUsForm = Loadable(
  lazy(() => import("./pages/AdminDashboard/UserFormSubmission"))
);
const AdminNewsLetterPage = Loadable(
  lazy(() => import("./pages/AdminDashboard/NewsLetter"))
);
const BookingForm = Loadable(lazy(() => import("./pages/BookingForm")));
const Profile = Loadable(lazy(() => import("./pages/Profile")));
const Search = Loadable(lazy(() => import("./pages/Search")));
const EventDetail = Loadable(lazy(() => import("./pages/EventDetail")));
const BookingSuccess = Loadable(lazy(() => import("./pages/BookingSuccess")));

function App() {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const user = localStorage.getItem("user");
  const history = useHistory();

  if (
    !(accessToken && refreshToken && user) &&
    !routesWithoutlocalStorage.includes(window.location.pathname) &&
    !window.location.pathname.includes("/neuespasswort") &&
    !window.location.pathname.includes("/pro-mitgliedschaft") &&
    !window.location.pathname.includes("profile") &&
    !window.location.pathname.includes("job") &&
    !window.location.pathname.includes("tipps") &&
    !window.location.pathname.includes("reels") &&
    !window.location.pathname.includes("alljobs") &&
    !window.location.pathname.includes("booking") &&
    !window.location.pathname.includes("newsletter") &&
    !window.location.pathname.includes("success") &&
    // !window.location.pathname.includes("home")&& 
    !window.location.pathname.includes("datenschutzerklaerung")&& 
    !window.location.pathname.includes("datenschutz")&& 
    !window.location.pathname.includes("impressum")&&
    !window.location.pathname.includes("agb")&&
    !window.location.pathname.includes("kontakt")&&
    !window.location.pathname.includes("ueber-uns")
    
  ) {
    history.push("/login");
  }

  if (
    window.location.pathname.includes("admin/unternehmen") &&
    JSON.parse(user)?.role !== "admin"
  ) {
    history.push("/");
  }

  return (
    <Router>
      <PublicLayout>
        <ToastContainer />
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact index path="/" component={Home} />
            <Route path="/home" component={Home} />
            <Route path="/registration" component={Register} />
            <Route path="/login" component={Login} />
            <Route path="/onboarding" component={CompanyRegistration} />
            <Route path="/neuespasswort" component={Forgot} />
            <Route path="/keinzugang" component={AccessDenied} />
            <Route path="/impressum" component={Impressum} />
            <Route path="/datenschutzerklaerung" component={Datenschutz} />
            <Route path="/agb" component={AGB} />
            <Route path="/tipps" component={Tipps} />
            <Route path="/reels" component={ReelPage} />
            <Route path="/alljobs" component={AllJobs} />
            <Route path="/widerrufsbelehrung" component={Widerrufsbelehrung} />
            <Route path="/zufriedene-kunden" component={ZufriedeneKunden} />
            <Route path="/newsletter" component={NewsLetterPage} />
            <Route path="/ueber-uns" component={UeberUns} />
            <Route path="/kontakt" component={Kontakt} />
            <Route path="/pro-mitgliedschaft" component={BookingForm} />
            <Route path="/success" component={BookingSuccess} />
            <Route path="/fehlerseite404" component={NotFound} />
            <Route path="/passwortzuruecksetzen/:id" component={ResetPassword} />
            <Route path="/Werbung/:id" component={AdPage} />
            <Route path="/job/:id" component={JobPage} />
            <Route path="/event/:id" component={EventDetail} />
            <Route
              path="/unternehmen/stammdaten/:id"
              component={UserDashboard}
            />
            <Route
              path="/unternehmen/einstellungen/:id"
              component={CompanySettings}
            />
            <Route
              path="/unternehmen/kontaktinformationen/:id"
              component={CompanyContact}
            />
            <Route
              path="/unternehmen/socials/:id"
              component={socialSettings}
            />
            <Route
              path="/unternehmen/oeffnungszeiten/:id"
              component={HoursSettings}
            />
            <Route
              path="/unternehmen/videos/:id"
              component={VideosSettings}
            />
            <Route
              path="/company/edit/manageevents/:id"
              component={ManageEvents}
            />
            <Route path="/unternehmen/jobs/:id" component={ManageJobs} />
            <Route
              path="/unternehmen/services/:id"
              component={ManageServices}
            />
            <Route path="/unternehmen/ueberuns/:id" component={ManageAbout} />
            <Route
              path="/unternehmen/preisliste/:id"
              component={MangePriceList}
            />
            <Route path="/unternehmen/speisekarte/:id" component={ManageMenuList} />
            <Route
              path="/unternehmen/partner/:id"
              component={ManagePartners}
            />
            <Route
              path="/unternehmen/favoriten/:id"
              component={ManageFavourite}
            />
            <Route
              path="/unternehmen/dokumente/:id"
              component={MangeFiles}
            />
            <Route path="/unternehmen/Werbung/:id" component={ad} />
            <Route path="/admin/unternehmen" component={ManageCompanies} />
            <Route path="/admin/badges" component={BadgesPage} />
            <Route path="/admin/branchen" component={Industries} />
            <Route path="/admin/edit/monitoring" component={Monitoring} />
            <Route path="/admin/newsletter" component={AdminNewsLetterPage} />
            <Route
              path="/admin/tipps"
              component={Recommendations}
            />
            <Route
              path="/admin/reels"
              component={Reels}
            />
            <Route path="/admin/werbung" component={CompanyAds} />
            <Route path="/admin/buchungen" component={userSubmissions} />
            <Route path="/admin/rollen" component={userRoles} />
            <Route path="/admin/kontaktaufnahmen" component={userContactUsForm} />
            <Route path="/admin/mitgliedschaftsarten" component={Subscription} />
            <Route path="/profile/:id" component={Profile} />
            <Route path="/suchen" component={Search} />

            {/* Catch-all route for 404 Not Found */}
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </PublicLayout>
    </Router>
  );
}

export default App;
