export const searchWithIndustry = (word,industry,isApproved = true) => {
    if(!word){
        if(isApproved){
            return {
                "query": {
                    "bool": {
                        "must": [
                            {
                                "match": { "industry": industry }
                            },
                            {
                                "match": { "isApproved": true}
                            }
                        ]
                    }
                }
            }
        }
        else{
            return {
                "query": {
                    "bool": {
                        "must": [
                            {
                                "match": { "industry": industry }
                            }
                        ]
                    }
                }
            }
        }
    }
    word = word?.toLowerCase();
    if(isApproved){
        return {
            "query": {
                "bool": {
                    "must": [
                        {
                            "match": { "industry": industry }
                        },
                        {
                            "match": { "isApproved": true}
                        } ,
                        { 
                            "bool": {
                                "should": [
                                    {
                                        "match": {
                                            "name": word
                                        }
                                    },
                                    {
                                        "match": {
                                            "services": word
                                        }
                                      },
                                    {
                                        "match": {
                                            "tagLine": word
                                        }
                                    },
                                    {  
                                        "regexp": {
                                            "name": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "about": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.formattedAddress": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.city": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.country": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.latitude": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                    
                                    {  
                                        "regexp": {
                                            "address.longitude": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "services": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "tagLine": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                ],
                            "minimum_should_match": 1
                        }
                        }
                    ]
                }
            }
        }
    }
    else{
        return {
            "query": {
                "bool": {
                    "must": [
                        {
                            "match": { "industry": industry }
                        },
                        { 
                            "bool": {
                                "should": [
                                    {
                                        "match": {
                                            "name": word
                                        }
                                    },
                                    {
                                        "match": {
                                            "services": word
                                        }
                                      },
                                    {
                                        "match": {
                                            "tagLine": word
                                        }
                                    },
                                    {  
                                        "regexp": {
                                            "name": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "about": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.formattedAddress": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.city": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.country": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.latitude": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                    
                                    {  
                                        "regexp": {
                                            "address.longitude": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "services": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "tagLine": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                ],
                            "minimum_should_match": 1
                        }
                        }
                    ]
                }
            }
        }
    }
}

export const searchIndludingIndustry = (word,isApproved = true) => {
    word = word?.toLowerCase();
    if(isApproved){
        return {
            "query": {
                "bool": {
                    "must": [
                        {
                            "match": { "isApproved": true}
                        } ,
                        { 
                            "bool": {
                                "should": [
                                    {
                                        "match": {
                                            "name": word
                                        }
                                    },
                                    {
                                        "match": {
                                            "services": word
                                        }
                                      },
                                    {
                                        "match": {
                                            "tagLine": word
                                        }
                                    },
                                    {  
                                        "regexp": {
                                            "name": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "about": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.formattedAddress": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.city": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.country": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.latitude": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                    
                                    {  
                                        "regexp": {
                                            "address.longitude": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "services": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "tagLine": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                ],
                            "minimum_should_match": 1
                        }
                        }
                    ]
                }
            }
        }
    }
    else{
        return {
            "query": {
                "bool": {
                    "must": [
                        { 
                            "bool": {
                                "should": [
                                    {
                                        "match": {
                                            "name": word
                                        }
                                    },
                                    {
                                        "match": {
                                            "services": word
                                        }
                                      },
                                    {
                                        "match": {
                                            "tagLine": word
                                        }
                                    },
                                    {  
                                        "regexp": {
                                            "name": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "about": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.formattedAddress": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.city": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.country": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.latitude": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                    
                                    {  
                                        "regexp": {
                                            "address.longitude": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "services": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "tagLine": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                ],
                            "minimum_should_match": 1
                        }
                        }
                    ]
                }
            }
        }
    }
}

export const searchWithoutIndustry = (word,isApproved = true) => {
    word = word?.toLowerCase();
    if(isApproved){
        return {
            "query": {
                "bool": {
                    "must": [
                        {
                            "match": { "isApproved": true}
                        } ,
                        { 
                            "bool": {
                                "should": [
                                    {
                                        "match": {
                                            "name": word
                                        }
                                    },
                                    {
                                        "match": {
                                            "services": word
                                        }
                                      },
                                    {
                                        "match": {
                                            "tagLine": word
                                        }
                                    },
                                    {  
                                        "regexp": {
                                            "name": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "about": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.formattedAddress": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.city": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.country": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.latitude": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                    
                                    {  
                                        "regexp": {
                                            "address.longitude": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "services": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "tagLine": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                ],
                            "minimum_should_match": 1
                        }
                        }
                    ]
                }
            }
        }
    }
    else{
        return {
            "query": {
                "bool": {
                    "must": [
                        { 
                            "bool": {
                                "should": [
                                    {
                                        "match": {
                                            "name": word
                                        }
                                    },
                                    {
                                        "match": {
                                            "services": word
                                        }
                                      },
                                    {
                                        "match": {
                                            "tagLine": word
                                        }
                                    },
                                    {  
                                        "regexp": {
                                            "name": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "about": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.formattedAddress": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.city": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.country": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "address.latitude": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                    
                                    {  
                                        "regexp": {
                                            "address.longitude": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "services": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                    {  
                                        "regexp": {
                                            "tagLine": {
                                            "value": `.*${word}.*`
                                            }
                                        }  
                                    },
                                ],
                            "minimum_should_match": 1
                        }
                        }
                    ]
                }
            }
        }
    }
}

export const searchAll = (isApproved = true) => {
    if(isApproved){
        return {
            "query": {
              "bool": {
                "must": [
                  { "match_all": {} }
                ],
                "filter": [
                  { "term": { "isApproved": true } }
                ]
              }
            }
          }
    }
    else{
        return {
            "query": {
              "match_all": {}
            }
        }
    }
}


export const searchSuggestionsQuery = (word) => {
    return {
        "suggest": {
          "name-suggest": {
            "prefix": word, 
            "completion": {
              "field": "name.suggest",
              "size": 50, 
              "skip_duplicates": true
            }
          },
          "services-suggest": {
            "prefix": word, 
            "completion": {
              "field": "services.suggest",
              "size": 50, 
              "skip_duplicates": true
            }
          },
          "tagLine-suggest": {
            "prefix": word, 
            "completion": {
              "field": "tagLine.suggest",
              "size": 50, 
              "skip_duplicates": true
            }
          }
        }
      }
}